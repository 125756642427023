var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-up"}},[(_vm.isActive)?_c('div',{staticClass:"content-container content-half"},[_c('v-form',{ref:"form"},[_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-column padding-div"},[(_vm.fluxo=='menor18' || _vm.fluxo=='convidadomenor18' || _vm.fluxo=='aberturamenor18')?_c('div',{staticClass:"fs-32px fw-700 color-dark"},[_vm._v(" Com quantos anos de idade o dependente deseja usar o benefício? "),_c('div',{staticClass:"fs-22px fw-500 color-gray"},[_vm._v(" Informe uma idade estimada."),_c('br')])]):_c('div',{staticClass:"fs-32px fw-700 color-dark"},[_vm._v(" Com quantos anos de idade você deseja usar o benefício? "),_c('div',{staticClass:"fs-22px fw-500 color-gray"},[_vm._v(" Informe uma idade estimada."),_c('br')]),(_vm.fluxo=='proprio')?_c('div',{staticClass:"fs-18px fw-500 color-gray"},[_vm._v(" Você poderá permanecer no Plano o tempo que desejar, mesmo sendo um período diferente ao informado nesse campo."),_c('br'),_vm._v(" Para aproveitar a melhor rentabilidade, o recomendável é que a pessoa permaneça no plano pelo maior tempo possível. ")]):_vm._e()]),_c('div',{staticClass:"single-input"},[_c('v-text-field-money',{staticClass:"input-caps",attrs:{"required":"","rules":[_vm.rules.required, _vm.rules.idade],"placeholder":"Digite sua resposta aqui","properties":{
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: true,
    placeholder: ' ',
  },"options":{
    locale: 'pt-BR',
    length: 3,
    precision: 0,
    empty: null,
  }},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.next($event)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.next($event)}},model:{value:(_vm.idadeBeneficio),callback:function ($$v) {_vm.idadeBeneficio=$$v},expression:"idadeBeneficio"}})],1)])])]),_c('div',{staticClass:"content-buttons"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"button-primary-bg-primary action-button txt-uppercase",on:{"click":_vm.next}},[_vm._v(" Avançar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("east")])],1)]),_c('div',{staticClass:"d-flex justify-content-end display-sm-none"},[_c('div',{staticClass:"fs-13px mt-3 display-sm-none fw-400"},[_vm._v("ou aperte "),_c('span',{staticClass:"fw-700"},[_vm._v("Enter")])])])])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }