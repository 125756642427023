<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="padding-div ">
                <div class="content-title">
                    <div class="fs-24px fw-700 color-primary secondary-text text-center mb-5">Termos e Condições filiação ABRAPP</div>
                </div>

                <div class="content-contents" v-if="fluxo=='aberturamaior18' || fluxo=='aberturamenor18'">
                    <div class="fs-20px fw-500 color-texto-escuro secondary-text ">
                        <span class="fw-700">Afiliação ABRAPP</span>
                        <br />
                        Para aderir ao plano é necessário que o novo participante seja filiado à ABRAPP (Associação Brasileira de Entidades Fechadas de Previdência Complementar), entidade sem fins lucrativos, representativa dos interesses comuns das Entidades Fechadas de Previdência Complementar.
                        <br />
                        <br />
                        <b class="color-primary fw-700">Este procedimento é gratuito</b> e o pedido de filiação deve ser realizado no ato desta inscrição.
                        <br />
                        <br />
                        Ao clicar em “Eu permito”, solicito minha filiação ao quadro associativo da ABRAPP na qualidade de Associado Especial Previdenciário Pessoa Física - Vinculado, exclusivamente para fins de inscrição ao Plano de Benefícios Setorial Prevaler oferecido pela Valia. Declaro que li e estou ciente do inteiro teor do <a v-bind:href="abrapp_url" target="_blank" class="color-primary fw-700">Estatuto Social da ABRAPP</a>, bem como dos direitos e deveres impostos aos seus membros, bem como declaro, para todos os fins e sob as penas da lei, ser segurado do Regime Geral de Previdência Social – RGPS nesta data, ciente de que a falsa declaração resultará no cancelamento da minha inscrição no plano.
                        <br />
                        <br />
                        Finalize seu processo de adesão imprimindo o pedido de filiação à ABRAPP e sua solicitação de inscrição no plano Prevaler.
                    </div>
                </div>
                <div class="content-contents" v-else>
                    <div class="fs-20px fw-500 color-texto-escuro secondary-text ">
                        <span class="fw-700">Afiliação ABRAPP</span>
                        <br />
                        Para aderir ao plano é necessário que o novo participante seja filiado à ABRAPP (Associação Brasileira de Entidades Fechadas de Previdência Complementar), entidade sem fins lucrativos, representativa dos interesses comuns das Entidades Fechadas de Previdência Complementar.
                        <br />
                        <br />
                        <b class="color-primary fw-700">Este procedimento é gratuito</b> e o pedido de filiação deve ser realizado no ato desta inscrição.
                        <br />
                        <br />
                        Ao clicar em “Eu permito”, o usuário solicita sua filiação ao quadro associativo da ABRAPP na qualidade de Associado Especial Previdenciário, exclusivamente para fins de inscrição ao Plano de Benefícios Setorial Prevaler, oferecido pela Valia. Declarando que leu e está ciente do inteiro teor do <a v-bind:href="abrapp_url" target="_blank" class="color-primary fw-700">Estatuto Social da ABRAPP</a>, bem como dos direitos e deveres impostos aos seus membros.
                        <br />
                        <br />
                        Finalize seu processo de adesão imprimindo o pedido de filiação à ABRAPP e sua solicitação de inscrição no plano Prevaler.
                    </div>
                </div>
                <div class="content-buttons">
                    <div class="d-flex justify-content-start align-items-center flex-column-mobile">
                        <button @click="postArquivosPedidosFiliacao(false,true)" class="button-primary-bg-primary mr-button mb-5 action-button txt-uppercase">
                            Eu permito
                        </button>
                        <button type="button" @click="postArquivosPedidosFiliacao(true, false)" class="btn fill-after action-button mb-5 txt-uppercase">
                            Imprimir Filiação
                        </button>
                        <a class="d-flex justify-content-center align-items-center action-button color-peb-preto h-100 mb-5" href="#">Quero sair da Adesão</a>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';
    import PlanoFamiliaAPI from "../../services/PlanoFamiliaAPI";
    import { mapState } from "vuex";
    import Enumerable from "linq";
    import constants from '../../constants';


    export default {
        name: 's06-termos',
        props: ['pessoa', 'visible', 'fluxo'],
        data: () => ({
            abrapp_url: constants.URL_ABRAPP,
            isActive: false,
            loading: false,
            selection: 1,
            step: 1,
            cpf: null,
            protocolo: null,
            arquivosPedidosFiliacao: null,
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        computed: {
            ...mapState(["smscode"]),
            ...mapState(["token"]),
            ...mapState(["userIP"]),
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;

                if (this.isActive) {
                    this.cpf = this.pessoa.cpf;
                    this.protocolo = this.pessoa.detalhesDaAdesao.protocolo;
                }
            },
            next: function () {
                this.$emit('next');
            },
            back: function () {
                this.$emit('back');
            },
            async postArquivosPedidosFiliacao(abreDocumento, anexaDocumento) {

                var retries = 3;
                var success = false;

                console.log(anexaDocumento);
                while (retries-- > 0 && !success) {
                    this.arquivosPedidosFiliacao = await this.carregaArquivosPedidosFiliacao();

                    if (this.arquivosPedidosFiliacao.isValid) {
                        if (this.pessoa.anexos === undefined || this.pessoa.anexos == null)
                            this.pessoa.anexos = [];

                        var list = Enumerable.From(this.pessoa.anexos)
                            .Where("$.titulo != 'Pedido_Filiacao.pdf' && $.titulo != 'Pedido de filiação a ABRAPP'").ToArray();
                        this.pessoa.anexos = list;

                        var urlArquivo = this.arquivosPedidosFiliacao.result.urlDownload;
                        urlArquivo = (urlArquivo.indexOf("?sv=") >= 0 ? urlArquivo.substring(0, urlArquivo.indexOf("?sv=")) : urlArquivo);
                        this.pessoa.anexos.push(
                            {
                                "tipo-documento": "Pedido de filiação a ABRAPP", //"Solicita��o de inscri��o no plano Prevaler",
                                "titulo": this.arquivosPedidosFiliacao.result.descricao,
                                "url": urlArquivo
                            },
                        );

                        if (abreDocumento)
                            window.open(this.arquivosPedidosFiliacao.result.urlDownload);

                        success = true;
                    }
                }

                if (!success) {
                    console.log("Erro generico ao tentar enviar o pedido de filiacao.")
                }

                if (!abreDocumento)
                    this.next();

            },
            async carregaArquivosPedidosFiliacao() {
                return await PlanoFamiliaAPI.postArquivosPedidosFiliacao(this.cpf, this.protocolo, this.smscode, this.userIP)
                    .then(response => {
                        return response.data;
                    })
                    .catch(e => {
                        console.log(e);
                        var r = {
                            "isValid": false
                        };
                        return r;
                    });
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

