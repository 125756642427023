import { render, staticRenderFns } from "./s04-preencheranalise-7-resultado.vue?vue&type=template&id=9fd6d3f0&scoped=true&"
import script from "./s04-preencheranalise-7-resultado.vue?vue&type=script&lang=js&"
export * from "./s04-preencheranalise-7-resultado.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fd6d3f0",
  null
  
)

export default component.exports