var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-up"}},[(_vm.isActive)?_c('div',{staticClass:"content-container content-half"},[_c('v-form',{ref:"form"},[_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-column padding-div"},[_c('div',{staticClass:"fs-28px fw-700 color-dark"},[_vm._v("Com qual valor você gostaria de contribuir mensalmente? "),_c('span',{staticClass:"fs-20px color-gray no-wrap"},[_vm._v("(mí­nimo R$ 50,00)")])]),_c('div',{staticClass:"single-input"},[_c('v-text-field-money',{staticClass:"input-caps",attrs:{"required":"","rules":[_vm.rules.required, _vm.rules.contribuicao],"placeholder":"Digite sua resposta aqui","properties":{
    prefix: 'R$',
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: true,
    placeholder: ' ',
  },"options":{
    locale: 'pt-BR',
    length: 11,
    precision: 2,
    empty: null,
  }},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.next($event)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.next($event)}},model:{value:(_vm.valorContribuicao),callback:function ($$v) {_vm.valorContribuicao=$$v},expression:"valorContribuicao"}})],1)])])]),_c('div',{staticClass:"content-buttons"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"button-primary-bg-primary action-button txt-uppercase",on:{"click":_vm.next}},[_vm._v(" Avançar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("east")])],1)]),_c('div',{staticClass:"d-flex justify-content-end display-sm-none"},[_c('div',{staticClass:"fs-13px fw-400  mt-3 display-sm-none"},[_vm._v("ou aperte "),_c('span',{staticClass:"fw-700"},[_vm._v("Enter")])])])])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }