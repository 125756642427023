<template>
    <transition name="slide-up">
        <div class="content-container content-container-inicio-penalty content-half" v-if="isActive">
            <!--getPessoa &&-->
            <div class="padding-div" v-if="isLocked">
                <div class="content-title">
                    <div class="fs-28px fw-700 color-dark mb-4 keep-text-same-line">Olá <div class="color-primary">{{getPessoa.nome.split(" ")[0].initCap()}}</div>,</div>
                    <div class="fs-28px fw-700 color-dark mb-0">Seu pedido de adesão ao Prevaler está concluído!</div>
                </div>
            </div>
            <div class="padding-div" v-else>
                <div class="content-title">
                    <div class="fs-28px fw-700 color-dark margin-bottom-46 keep-text-same-line" v-if="fluxo=='maior18' || fluxo=='convidadomaior18' || fluxo=='aberturamaior18' ">Oi, <div class="color-primary">{{getPessoa.nome.split(" ")[0].initCap()}}</div>!</div>
                    <div class="fs-28px fw-700 color-dark margin-bottom-46 keep-text-same-line" v-else-if="fluxo=='menor18' || fluxo=='proprio'|| fluxo=='propriodpub'">Oi, <div class="color-primary">{{getPessoa["participante-valia"].nome.split(" ")[0].initCap()}}</div>!</div>
                    <div class="fs-28px fw-700 color-dark margin-bottom-46 keep-text-same-line" v-else-if="(fluxo=='convidadomenor18' || fluxo=='aberturamenor18') && this.pessoa['representante'] !== undefined && this.pessoa['representante'].nome !== undefined && this.pessoa['representante'].nome!=null">Oi, <div class="color-primary">{{this.pessoa['representante'].nome.split(" ")[0].initCap()}}</div>!</div>
                    <div class="fs-28px fw-700 color-dark margin-bottom-46 keep-text-same-line" v-else>Oi, <div class="color-primary">{{getPessoa["participante-valia"].nome.split(" ")[0].initCap()}}</div>!</div>

                    <div class="fs-28px fw-700 color-dark margin-bottom-42" v-if="fluxo=='proprio'|| fluxo=='propriodpub' ">Vamos iniciar sua adesão ao prevaler.</div>
                    <div class="fs-28px fw-700 color-dark margin-bottom-42" v-if="fluxo=='maior18' || fluxo=='convidadomaior18'|| fluxo=='aberturamaior18'">Você está a poucos passos para realizar seus sonhos. Vamos iniciar?</div>
                    <div class="fs-28px fw-700 color-dark margin-bottom-42" v-if="fluxo=='menor18' || fluxo == 'convidadomenor18' || fluxo=='aberturamenor18'">
                        Você está a poucos passos para realizar os sonhos do(a) {{getPessoa.nome.split(" ")[0].initCap()}}.<br />Vamos iniciar?
                    </div>
                </div>
                <div class="content-contents">
                    <h5 class="fs-26px fw-600 color-dark mt-3 margin-bottom-28">Ao clicar em iniciar, você:</h5>
                    <h5 class="fs-26px fw-500 line-height-42 color-gray">
                        Autoriza o recebimento de informações sobre o Prevaler;<br />
                        Concorda em compartilhar os dados fornecidos para fins de contratação do plano.
                    </h5>
                </div>

                <div class="content-buttons">
                    <div class="fs-24px valia-tooltip">
                        Saiba mais
                        <span class="valia-tooltiptext">
                            <span class="fw-600">Autorização de Recebimento de E-mail e compartilhamento de dados.</span>
                            <br />
                            Ao clicar em iniciar, será autorizado o recebimento de informações relacionadas com o Plano Prevaler pelos meios de contato disponibilizados por você, assim como será autorizado que os dados fornecidos sejam compartilhados para os fins de contratação de uso deste plano.
                        </span>
                    </div>
                </div>

                <div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button v-on:click="next" class="button-primary-bg-primary action-button">
                            INICIAR
                            <v-icon right>east</v-icon>
                        </button>
                    </div>
                    <!--<div class="d-flex justify-content-end display-sm-none">
                        <div class="fs-13px mt-3 display-sm-none">ou aperte <span class="fw-700">Enter</span></div>
                    </div>-->
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';
    import StepTracker from "../../services/StepTracker";

    export default {
        name: 's00-inicio',
        props: ['pessoa', 'visible', 'fluxo', 'number'],
        data: () => ({
            isActive: false,
            loading: false,
            selection: 1,
            isLocked: false,
            trackMsg: null,
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        computed: {
            getPessoa() {
                return this.pessoa
            },
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;
                this.isLocked = (this.pessoa.detalhesDaAdesao.status != "Em Andamento" ? true : false);
                this.trackMsg = null;
            },
            next: function () {
                StepTracker.registerStep(this.number, this.trackMsg);
                this.$emit('next');
            }
        },
        created() {
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

